<template>
  <section class="section has-background-eurotext-light has-text-white-bis">
    <div class="container">
      <div class="columns is-vcentered all-page">
        <div class="column center-content">
          <div class="">
            <b-field
              v-if="isRCSign"
              :label="$t('sign.fiscal_year_heading')"
              custom-class="has-text-white-bis"
            >
              <b-input
                v-model="fiscalYear"
                :disabled="nextActionAllowed === actions.DEACTIVATE"
                type="text"
                pattern="[0-9]{4}"
                :placeholder="$t('sign.fiscal_year_placeholder')"
              ></b-input>
            </b-field>
            <b-field
              v-else
              :label="$t('sign.max_progressive_heading')"
              custom-class="has-text-white-bis"
            >
              <b-input
                v-model="progressive"
                type="number"
                :placeholder="$t('sign.max_progressive_placeholder')"
              ></b-input>
            </b-field>
            <b-field label="PIN" custom-class="has-text-white-bis">
              <b-input
                v-model="pin"
                placeholder="Pin"
                type="password"
                expanded
                :disabled="!nextActionAllowed || !activeNotifications"
              ></b-input>
              <!-- <p class="control">
              <span :class="`button custom-is-static ${statusClass}`">{{
                message
              }}</span>
            </p> -->
              <b-tag :class="statusClass" size="is-large">{{ message }}</b-tag>
            </b-field>
            <!-- <div class="buttons has-addons"> -->
            <div
              class="buttons"
              v-for="(action, key) in possibleActions"
              :key="key"
            >
              <b-button expanded @click="activate(action)" :disabled="!pin">{{
                $t(`sign.label.${action}`)
              }}</b-button>
            </div>
            <div class="buttons" v-show="!status">
              <b-button :loading="!status" expanded></b-button>
            </div>
            <div class="buttons center-content">
              <b-button
                :disabled="activeNotifications"
                type="is-primary"
                @click="activate(actions.START_NOTIFICATIONS)"
                >{{ $t(`sign.label.${actions.START_NOTIFICATIONS}`) }}</b-button
              >
              <b-button
                :disabled="!activeNotifications"
                type="is-primary"
                @click="activate(actions.STOP_NOTIFICATIONS)"
                >{{ $t(`sign.label.${actions.STOP_NOTIFICATIONS}`) }}</b-button
              >
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  KUrls,
  socketStatusCodes,
  basicSocketActions,
  socketActions,
} from "@/helpers/constants";
import { authHeader } from "@/helpers/authHeader.js";
export default {
  name: "App",
  data() {
    return {
      className: null,
      webSocket: null,
      pin: "",
      message: this.$t("sign.status.waiting"),
      status: "",
      nextActionAllowed: "",
      messageColor: "",
      fiscalYear: new Date().getUTCFullYear() - 1,
      progressive: "",
      actions: { ...socketActions },
      activeNotifications: true,
    };
  },
  computed: {
    isRCSign() {
      if (this.$route.path.includes(KUrls.routes.RC_SIGNATURE)) {
        return true;
      } else {
        return false;
      }
    },
    possibleActions() {
      const possible = {};
      if (this.nextActionAllowed) {
        if (this.nextActionAllowed === basicSocketActions.DEACTIVATE) {
          possible.DEACTIVATE = basicSocketActions.DEACTIVATE;
        } else if (this.nextActionAllowed === basicSocketActions.ACTIVATE) {
          possible.ACTIVATE = basicSocketActions.ACTIVATE;
          // possible.ACTIVATE_PARTIAL = basicSocketActions.ACTIVATE_PARTIAL;
        }
      }
      return possible;
    },
    statusClass() {
      var style = "is-dark";
      if (this.status) {
        switch (this.status) {
          case socketStatusCodes.DEACTIVATED:
            style = "is-warning";
            break;
          case socketStatusCodes.WRONG_PIN:
          case socketStatusCodes.UNAUTHORIZED_USER:
          case socketStatusCodes.WRONG_STOP_PIN:
            style = "is-danger";
            break;
          case socketStatusCodes.ACTIVATED_PARTIAL:
          case socketStatusCodes.ACTIVATED:
            style = "is-success";
            break;
          default:
            style = "is-dark";
        }
      }
      return style;
    },
    closed() {
      return this.webSocket && this.webSocket.readyState === WebSocket.CLOSED;
    },
    open() {
      return this.webSocket && this.webSocket.readyState === WebSocket.OPEN;
    },
  },
  methods: {
    activate: function (action) {
      // console.log(this.webSocket);
      const msg = { action, pin: this.pin };
      if (action === basicSocketActions.ACTIVATE) {
        if (this.isRCSign && this.fiscalYear) {
          msg.action = socketActions.ACTIVATE_PARTIAL;
          msg.fiscalYear = this.fiscalYear;
        } else if (!this.isRCSign && this.progressive) {
          msg.action = socketActions.ACTIVATE_PARTIAL;
          msg.progressive = this.progressive;
        }
        this.sendMessage(msg);
        // this.promptDialog(msg);
      } else if (action === socketActions.STOP_NOTIFICATIONS) {
        this.sendMessage(msg);
        this.activeNotifications = false;
        this.message = this.$t("sign.status.stopped_notifications");
        this.status = "";
        this.nextActionAllowed = "";
      } else if (action === socketActions.START_NOTIFICATIONS) {
        this.message = this.$t("sign.status.started_notifications");
        this.sendMessage(msg);
        this.activeNotifications = true;
      } else {
        this.sendMessage(msg);
      }
      this.pin = "";
      // msg.maxProgressive = 99999999;
    },
    sendMessage(msg) {
      const json = JSON.stringify(msg);
      this.webSocket.send(json);
    },
    promptDialog(msg) {
      if (this.isRCSign) {
        this.$buefy.dialog.prompt({
          message: this.$t("sign.fiscal_year_heading"),
          inputAttrs: {
            type: "text",
            pattern: "[0-9]{4}",
            placeholder: this.$t("sign.fiscal_year_placeholder"),
            required: true,
          },
          trapFocus: true,
          onConfirm: (value) => {
            msg.fiscalYear = value;
            this.sendMessage(msg);
          },
        });
      } else {
        this.$buefy.dialog.prompt({
          message: this.$t("sign.max_progressive_heading"),
          inputAttrs: {
            type: "number",
            placeholder: this.$t("sign.max_progressive_placeholder"),
            required: true,
          },
          trapFocus: true,
          onConfirm: (value) => {
            msg.progressive = value;
            this.sendMessage(msg);
          },
        });
      }
    },
  },
  mounted() {
    this.className = this.$route.query.class_name;

    console.log("Starting connection to WebSocket Server");
    const token = authHeader();
    const urlConf = KUrls.web_socket;
    let urlProtocol = "";
    if (urlConf.PROTOCOL) {
      urlProtocol = urlConf.PROTOCOL;
    } else {
      urlProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    }
    const originUrl = `${urlProtocol}://${
      urlConf.HOST ? urlConf.HOST : window.location.host
    }${urlConf.ENDPOINT}`;
    const wsUrl = this.isRCSign
      ? `${originUrl}${KUrls.VOLUME_SERVICE}`
      : `${originUrl}${KUrls.MASSIVE_SIGN_SERVICE}/${this.$route.params.company}/${this.$route.query.class_name}`;
    this.webSocket = new WebSocket(`${wsUrl}?token=${token}`, []);

    this.webSocket.onerror = (event) => {
      console.error("WebSocket error observed:", event);
    };

    this.webSocket.onmessage = (event) => {
      console.log(event);
      var response = JSON.parse(event.data);
      const { status } = response;
      console.log(status);
      this.status = status;
      this.nextActionAllowed = response.nextActionAllowed;
      switch (status) {
        case socketStatusCodes.DEACTIVATED:
          this.message = this.$t("sign.status.not-active");
          break;
        case socketStatusCodes.WRONG_PIN:
          this.message = this.$t("sign.status.wrong-pin");
          break;
        case socketStatusCodes.UNAUTHORIZED_USER:
          this.message = this.$t("sign.status.wrong-user");
          break;
        case socketStatusCodes.ACTIVATED:
          this.message = this.$t("sign.status.active");
          break;
        case socketStatusCodes.WRONG_STOP_PIN:
          this.message = this.$t("sign.status.wrong-stop-pin");
          break;
        case socketStatusCodes.ACTIVATED_PARTIAL:
          if (response.maxProgressive) {
            this.progressive = response.maxProgressive;
          }
          if (response.fiscalYear) {
            this.fiscalYear = response.fiscalYear;
          }
          this.message = this.$t("sign.status.active");
          // this.message = this.$t("sign.status.partially-active", {
          //   progressive: maxProgressive,
          // });
          break;
        default:
          console.log(event.data);
      }
      // onOpen - response: {"maxProgressive":0,"status":1,"fiscalYear":0}
    };

    this.webSocket.onopen = (event) => {
      console.log(event);
      console.log("Successfully connected to the websocket server...");
    };

    this.webSocket.onclose = (event) => {
      console.log("WebSocket is closed now.", event);
    };
  },
  beforeDestroy() {
    console.log("Closing web socket");
    this.webSocket.close();
  },
};
</script>

<style scoped>
.all-page {
  height: 100vh;
}
.center-content {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}
.custom-is-static {
  pointer-events: none;
}
</style>
